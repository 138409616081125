
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import {
  IssuePriority,
  PriorityTypes,
} from "@/store/modules/priority/priority.types";
import { AddSiteZone, ZoneTypes } from "@/store/modules/zone/zone.types";
import { DefaultState, Pagination } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const zoneX = namespace(ZoneTypes.MODULE);
const priorityX = namespace("Priority");

@Component({
  components: { DatePicker },
})
export default class AddZone extends Mixins(ValidationMixin) {
  @zoneX.State(ZoneTypes.ADD_ZONE_STATE)
  public addZone!: DefaultState;

  @zoneX.Mutation(ZoneTypes.SET_ADD_ZONE_DIALOG)
  public setAddZone!: (addZone: boolean) => void;

  @zoneX.Action(ZoneTypes.ADD_ZONE)
  public saveZone!: (farmer: AddSiteZone) => Promise<void>;

  @priorityX.Action(PriorityTypes.LOAD_PRIORITIES)
  public getIssuePriorities!: (pagination: Pagination) => void;

  @priorityX.State(PriorityTypes.PRIORITIES)
  public ticketPriorities!: IssuePriority[];

  public addZoneStep = 1;

  public isValid = false;

  public zone: AddSiteZone = {
    name: "",
    description: "",
    active: true,
    slas: [],
  };

  @Ref("form") public formRef!: Validator;

  closeAddZoneDialog(): void {
    this.setAddZone(false);
  }

  async saveZoneToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveZone(this.zone);
    }
  }

  @Watch("ticketPriorities")
  prioritiesChanged(): void {
    this.zone.slas = this.ticketPriorities.map((x) => {
      return {
        priority: x.name,
        sla: 0,
      };
    });
  }

  mounted(): void {
    this.getIssuePriorities({ page: 0, limit: 10 });
    this.prioritiesChanged();
  }
}
